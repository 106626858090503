<template>
  <div class="intro-y box rounded-xl p-5 mt-5">
    <wallets-summary :tab="tab" />
  </div>
  <div>
    <TableFilter
      :disabled="loading"
      :filter-fields="filterFields"
      @filter="doFilter"
      @reset="doResetFilter"
    ></TableFilter>
    <div class="intro-y box p-5 mt-5">
      <div class="col-span-12 lg:col-span-12">
        <div
          class="flex flex-col sm:flex-row sm:items-end xl:items-start justify-end"
        >
          <app-action-toolbar
            @onPrint="doPrint"
            @onExportXlsx="onExportXlsx"
            @onExportAllXlsx="onExportAllXlsx"
            @onDownloadPdf="doDownloadPdf"
          ></app-action-toolbar>
        </div>
        <!-- BEGIN: HTML Table Data -->
        <div ref="tableRef" class="relative">
          <loading-spinner
            type="half-circle"
            :duration="1000"
            :size="40"
            color="#4469A8"
            :loading="loading"
          />
          <div class="overflow-x-auto">
            <table
              id="walletsTable"
              ref="tabulator"
              class="table table-report sm:my-2"
            >
              <thead>
                <tr>
                  <th class="whitespace-nowrap text-center" id="">#</th>
                  <th
                    v-for="col in columns"
                    :key="col.name"
                    :id="
                      col.name === 'action'
                        ? 'ignore-2'
                        : col.name === 'avatar'
                        ? 'ignore-3'
                        : ''
                    "
                    class="text-center whitespace-nowrap"
                  >
                    {{ i18n(col.label) }}
                  </th>
                </tr>
              </thead>
              <tbody v-if="rows.length">
                <tr
                  v-for="(row, index) in rows"
                  :key="index"
                  class="intro-x"
                  :class="{ removedRow: row.isRemoved }"
                >
                  <td class="w-40">
                    <div
                      class="text-gray-600 text-xs whitespace-nowrap mt-0.5 text-center"
                    >
                      {{ rowIndex(index) }}
                    </div>
                  </td>
                  <td class="text-center">
                    <div class="font-medium whitespace-nowrap">
                      {{ presenter(row[tab], 'code') }}
                    </div>
                  </td>
                  <td class="text-center">
                    <div class="font-medium whitespace-nowrap">
                      {{ presenter(row[tab], 'fullName') }}
                    </div>
                  </td>
                  <td class="text-center">
                    <div
                      class="font-medium whitespace-nowrap"
                      style="direction: ltr !important"
                    >
                      {{ presenter(row[tab], 'phoneNumber') }}
                    </div>
                  </td>
                  <td class="text-center">
                    <div
                      class="font-medium whitespace-nowrap text-theme-10 dark:text-theme-30"
                    >
                      {{ presenterPrice(row.balance) }}
                    </div>
                  </td>
                  <td class="text-center">
                    <div
                      class="font-medium whitespace-nowrap text-green-500 dark:text-theme-30"
                    >
                      {{ presenterPrice(row.actualBalance) }}
                    </div>
                  </td>
                  <!-- <td class="text-center">
                    <div class="font-medium whitespace-nowrap">
                      {{ presenterPrice(row.dayAmount || 0) }}
                    </div>
                  </td>
                  <td class="text-center">
                    <div class="font-medium whitespace-nowrap">
                      {{ presenterPrice(row.weekAmount || 0) }}
                    </div>
                  </td>
                  <td class="text-center">
                    <div class="font-medium whitespace-nowrap">
                      {{ presenterPrice(row.monthAmount || 0) }}
                    </div>
                  </td> -->
                  <td class="text-center">
                    <div class="font-medium whitespace-nowrap">
                      {{ presenterPrice(row.yearAmount || 0) }}
                    </div>
                  </td>
                  <td class="text-center">
                    <div class="font-medium whitespace-nowrap">
                      {{ presenterDate(row, 'createdAt') }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <app-empty-page
              v-if="!rows.length && !loading"
              :label="i18n('empty.wallet')"
              label-class="text-lg font-medium"
              icon-size="4x"
              class="box w-full py-64"
            ></app-empty-page>
          </div>
          <Pagination
            v-if="rows.length"
            :page="currentPage"
            :pageSize="pagination.limit"
            :rowsNumber="pagination.total"
            :showSizeChanger="true"
            :hasNext="hasNext"
            :hasPrev="hasPrev"
            :loading="loading"
            :disabled="!rows.length"
            position="center"
            @next-page="doNextPage"
            @prev-change="doPrevPage"
            @size-change="doChangePaginationPageSize"
            @page-change="goToPage"
          ></Pagination>
        </div>
        <!-- END: HTML Table Data -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import WalletsSummary from '@/views/wallet/wallets-summary.vue'
import { paginationMixin } from '@/mixins/pagination-mixin'
import { presenterMixin } from '@/mixins/presenter-mixin'
import { exportPrintMixin } from '@/mixins/export-print-mixin'

export default {
  name: 'app-wallet-list-page',
  mixins: [paginationMixin, presenterMixin, exportPrintMixin],
  props: {
    tab: {
      type: String
    }
  },
  components: {
    WalletsSummary
  },
  data() {
    return {
      // data important for Mixins
      tableRef: null,
      exportTableName: 'wallets',
      excludedCols: [7],
      ignoreElements: ['ignore-1', 'ignore-2'],
      // component Data
      selectedId: null,
      columns: [
        {
          name: 'code',
          field: 'code',
          label: 'iam.fields.code',
          align: 'center'
        },
        {
          name: 'userName',
          field: 'userName',
          label: 'wallet.fields.userName',
          align: 'center'
        },
        {
          name: 'phoneNumber',
          field: 'phoneNumber',
          label: 'wallet.fields.phoneNumber',
          align: 'center'
        },
        {
          name: 'balance',
          field: 'balance',
          label: 'wallet.fields.balance',
          align: 'center'
        },
        {
          name: 'actualBalance',
          field: 'actualBalance',
          label: 'wallet.fields.actualBalance',
          align: 'center'
        },
        // {
        //   name: 'dayAmount',
        //   field: 'dayAmount',
        //   label: `wallet.fields.dayAmount${
        //     this.tab === 'client' ? 'Client' : ''
        //   }`,
        //   align: 'center'
        // },
        // {
        //   name: 'weekAmount',
        //   field: 'weekAmount',
        //   label: `wallet.fields.weekAmount${
        //     this.tab === 'client' ? 'Client' : ''
        //   }`,
        //   align: 'center'
        // },
        // {
        //   name: 'monthAmount',
        //   field: 'monthAmount',
        //   label: `wallet.fields.monthAmount${
        //     this.tab === 'client' ? 'Client' : ''
        //   }`,
        //   align: 'center'
        // },
        {
          name: 'yearAmount',
          field: 'yearAmount',
          label: `wallet.fields.yearAmount${
            this.tab === 'client' ? 'Client' : ''
          }`,
          align: 'center'
        },
        {
          name: 'createdAt',
          field: 'createdAt',
          label: 'wallet.fields.createdAt',
          align: 'center'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      rows: 'wallet/list/rows',
      hasNext: 'wallet/list/hasNext',
      hasPrev: 'wallet/list/hasPrev',
      pagination: 'wallet/list/pagination',
      listLoading: 'wallet/list/loading',
      exportLoading: 'exportStore/loading'
    }),
    fetchOptions() {
      return { type: this.tab }
    },
    exportResourceName() {
      return `wallet-${this.tab}`
    },
    loading() {
      return this.listLoading || this.exportLoading
    },
    currentPage() {
      return this.pagination.currentPage || 1
    },
    rowIndex() {
      return index => {
        return index + this.pagination.limit * (this.currentPage - 1) + 1
      }
    },
    filterFields() {
      return [
        {
          label: this.i18n('iam.fields.code'),
          value: 'code',
          type: 'text'
        },
        {
          label: this.i18n('chefs.filter.fullName'),
          value: 'fullName',
          type: 'text'
        },
        {
          label: this.i18n('chefs.filter.phoneNumber'),
          value: 'phoneNumber',
          type: 'text'
        },
        {
          label: this.i18n('wallet.fields.balance'),
          value: 'balance',
          type: 'number-range'
        },
        {
          label: this.i18n('wallet.fields.havingBalance'),
          value: 'balance',
          type: 'listWithOperator',
          options: this.balanceOptions
        },
        {
          label: this.i18n('wallet.fields.createdAt'),
          value: 'createdAt',
          type: 'date-range'
        }
      ]
    },
    balanceOptions() {
      return [
        {
          label: this.i18n('common.yes'),
          value: 0,
          operator: 'greater'
        },
        {
          label: this.i18n('common.no'),
          value: 0,
          operator: 'equal'
        }
      ]
    }
  },
  async mounted() {
    await this.doFetchFirstPage()
  },
  watch: {
    tab() {
      this.doFetchFirstPage()
    }
  },
  methods: {
    ...mapActions({
      doFetch: 'wallet/list/doFetch',
      doFetchNextPage: 'wallet/list/doFetchNextPage',
      doFetchPreviousPage: 'wallet/list/doFetchPreviousPage',
      doChangePaginationCurrentPage:
        'wallet/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize: 'wallet/list/doChangePaginationPageSize',
      doExport: 'exportStore/doExport'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
}
</script>
<style scoped>
.table-report:not(.table-report--bordered):not(.table-report--tabulator) tr {
  border-radius: 0px !important;
  box-shadow: 15px 0px 15px #0000000b;
}

.table-report:not(.table-report--bordered):not(.table-report--tabulator) td {
  border-radius: 0px !important;
  box-shadow: unset !important;
}

.pagination-ar {
  margin-right: unset !important;
  margin-left: auto !important;
}
</style>
