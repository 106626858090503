<template>
  <div class="intro-y flex items-center mt-8">
    <h2 class="text-lg font-medium" :class="isRTL ? 'ml-auto' : 'mr-auto'">
      {{ i18n('wallet.title') }}
    </h2>
  </div>
  <div class="intro-y box px-5 pt-5 mt-5">
    <div
      class="nav nav-tabs flex-col sm:flex-row justify-center lg:justify-start"
      role="tablist"
    >
      <a
        id="wallet-client-tab"
        data-toggle="tab"
        data-target="#clients"
        href="javascript:;"
        class="flex items-center gap-2 py-4 sm:mr-8 active"
        role="tab"
        aria-controls="requests"
        aria-selected="true"
        @click="changeTab('client')"
      >
        <app-i18n code="wallet.clients"></app-i18n>
      </a>
      <a
        id="wallet-chef-tab"
        data-toggle="tab"
        data-target="#chefs"
        href="javascript:;"
        class="flex items-center gap-2 py-4 sm:mr-8"
        role="tab"
        aria-controls="requests"
        aria-selected="true"
        @click="changeTab('chef')"
      >
        <app-i18n code="wallet.chefs"></app-i18n>
      </a>
      <a
        id="wallet-captain-tab"
        data-toggle="tab"
        data-target="#rcaptains"
        href="javascript:;"
        class="flex items-center gap-2 py-4 sm:mr-8"
        role="tab"
        aria-controls="requests"
        aria-selected="true"
        @click="changeTab('captain')"
      >
        <app-i18n code="wallet.captains"></app-i18n>
      </a>
    </div>
  </div>
  <div class="intro-y tab-content mt-5">
    <wallet-list-table :tab="tab" :key="tab" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import WalletListTable from '@/views/wallet/wallet-list-page.vue'

export default {
  name: 'app-wallet-list-page',
  components: {
    WalletListTable
  },
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.wallets')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.wallets')
    }
  },
  data() {
    return {
      tab: 'client'
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode'
    })
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args)
    },
    changeTab(tab) {
      this.tab = tab
    }
  }
}
</script>
