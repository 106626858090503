<template>
  <section>
    <div class="grid grid-cols-12 gap-4 md:gap-16 mt-2 py-3">
      <div class="col-span-12 sm:col-span-6 intro-y">
        <app-wallet-card
          v-if="!loading"
          :amount="model[tab].total"
          :label="i18n(totalLabel)"
          icon="/images/icons/ic-money-1.png"
        ></app-wallet-card>
        <app-wallet-card-loading v-else />
      </div>
      <div class="col-span-12 sm:col-span-6 intro-y">
        <app-wallet-card
          v-if="!loading"
          :amount="model[tab].actual"
          :label="i18n(actualLabel)"
          icon="/images/icons/ic-wallet-1.png"
        ></app-wallet-card>
        <app-wallet-card-loading v-else />
      </div>
    </div>
  </section>
</template>
<script>
import WalletCard from '@/views/wallet/wallet-card.vue'
import WalletCardLoading from '@/views/wallet/wallet-card-loading.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    [WalletCard.name]: WalletCard,
    [WalletCardLoading.name]: WalletCardLoading
  },
  props: {
    tab: {
      type: String
    }
  },
  computed: {
    ...mapGetters({
      model: 'dashboard/statistics'
    }),
    loading() {
      return !this.model[this.tab]
    },
    actualLabel() {
      return this.tab === 'client'
        ? 'wallet.clientsActual'
        : this.tab === 'chef'
        ? 'wallet.chefsActual'
        : 'wallet.captainsActual'
    },
    totalLabel() {
      return this.tab === 'client'
        ? 'wallet.clientsTotal'
        : this.tab === 'chef'
        ? 'wallet.chefsTotal'
        : 'wallet.captainsTotal'
    }
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
}
</script>

<style lang="scss" scoped>
.card-body {
  background: #ffffff !important;
  border: 2px solid rgba(0, 0, 0, 0.12) !important;
  padding: 0 !important;
  border-radius: 15px !important;
  margin-bottom: 1rem !important;
}

.content-section {
  padding: 20px !important;
}

.card-actions {
  background: #f8f8f8 !important;
  padding: 1.5rem !important;
  border-bottom-left-radius: 15px !important;
  border-bottom-right-radius: 15px !important;

  button {
    font-weight: 600 !important;
    font-size: 1rem !important;
    line-height: 1.5rem !important;
    text-decoration: underline !important;
    color: #515151 !important;
  }
}

.available-balance {
  padding: 0.25rem !important;
  font-weight: 400 !important;
  font-size: 0.75rem !important;

  .balance {
    color: #13b177 !important;
    font-weight: 600 !important;
  }
}

.title {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 0.9375rem !important;
  line-height: 1.5rem !important;
  color: #000000 !important;
  margin-right: 0.5rem !important;
}

.amount {
  font-style: normal;
  font-weight: 600;
  font-size: 1.75rem;
  line-height: 2rem;
  margin-top: 0.5rem;
}
</style>

<style lang="scss" scoped>
@media (max-width: 1439px) {
  .q-card {
    // min-height: calc(80px + (77 - 80) * ((100vw - 320px) / (1440 - 320))) !important;
    border-radius: calc(
      6px + (15 - 6) * ((100vw - 320px) / (1440 - 320))
    ) !important;
  }

  .content-section {
    padding: calc(
      12px + (20 - 12) * ((100vw - 320px) / (1440 - 320))
    ) !important;
  }
  .card-actions {
    padding: calc(
      12px + (24 - 12) * ((100vw - 320px) / (1440 - 320))
    ) !important;
  }

  .avatar {
    margin-right: calc(
      8px + (12 - 8) * ((100vw - 320px) / (1440 - 320))
    ) !important;
  }

  .amount {
    font-size: calc(
      16px + (28 - 16) * ((100vw - 320px) / (1440 - 320))
    ) !important;
  }

  .details-label {
    font-size: calc(
      12px + (16 - 12) * ((100vw - 320px) / (1440 - 320))
    ) !important;
    line-height: calc(
      18px + (24 - 18) * ((100vw - 320px) / (1440 - 320))
    ) !important;
  }

  .details-value {
    font-size: calc(
      10px + (12 - 10) * ((100vw - 320px) / (1440 - 320))
    ) !important;
    line-height: 18px;
  }
}
</style>
